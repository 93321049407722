import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import api from './utils/api'


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.directive('throttle', {
  bind: (el, binding) => {
    let throttleTime = binding.value; // 防抖时间
    if (!throttleTime) { // 用户若不设置防抖时间，则默认2s
      throttleTime = 2000;
    }
    let cbFun;
    el.addEventListener('click', event => {
      if (!cbFun) { // 第一次执行
        cbFun = setTimeout(() => {
          cbFun = null;
        }, throttleTime);
      } else {
        event && event.stopImmediatePropagation();
      }
    }, true);
  },
});

Vue.prototype.$api = api;

import request from './utils/request'
Vue.prototype.$request = request;

import until from './utils/until'
Vue.prototype.$until = until

Vue.config.productionTip = false
Vue.prototype.$logoBig = 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/zhongtuo/logoBig.png'
Vue.prototype.$placeImg =  'https://zhtuoyupt.obs.cn-north-4.myhuaweicloud.com/files/2(1).png'
Vue.prototype.$placeImgBg = 'https://zhtuoyupt.obs.cn-north-4.myhuaweicloud.com/files/2(1).png'
Vue.prototype.$placeImgSmall = 'https://zhtuoyupt.obs.cn-north-4.myhuaweicloud.com/files/2(1).png'
Vue.prototype.$vip = 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/vip.png'
// Vue.prototype.$noVip = 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/noVip.png'
Vue.prototype.$noVip = 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/opacity.png' //透明图片

Vue.prototype.$free = 'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/free.png'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
