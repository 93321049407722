<template>
  <div id="app">
    <Header v-show="$route.name != 'search'"></Header>
    <div class="appContent" :class="{whiteBg:isWhiteBg}" ref="appContent">
      <div
        class="main tuoyu"
        :class="{
          vipMain: $route.name == 'detailVip',
          searchMain: $route.name == 'search',
        }"
      >
        <router-view />
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import vuexInit from "./utils/vuexInit";
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data(){
    return{
      isWhiteBg:false,
    }
  },
  watch: {
    $route() {
      // this.$request.userOnly()
      let main = this.$refs.appContent;
      let arr = ['certList','certForm','examinList','examinResult','examinResultImitate','examinVideoResult','examinVideoUpload','examinResultDetail']
      this.isWhiteBg = arr.findIndex(item=> this.$route.path.indexOf(item)!=-1)!=-1
      main.scrollTo(0, 0);
    },
  },
  mounted() {
    console.log(window.location)
    // this.$request.userOnly()
    this.setInit();
    // this.screenSize()
    // localStorage.removeItem('vuex')
    this.$router.onReady(() => {
      if (this.$route.params.plat_type) {
        this.$store.commit("SET_PLAT", this.$route.params.plat_type);
      } else if (localStorage.getItem("plat")) {
        let url = "/" + localStorage.getItem("plat") + window.location.hash.slice(1);
        this.$router.replace({
          path: url,
        });
        // window.location.replace = url
      }
      window.addEventListener('resize',this.screenSize())
      this.autoLogin();
    });
  },
  methods: {
    screenSize(){
      let screenWidth = document.body.clientWidth;
      let hash = window.location.hash
      let windowUrl = window.location.href
      let url = ''
      let search = hash.indexOf('?')>-1 ? '?' + hash.split('?')[1] : ''
      console.log(screenWidth,this.$route.params.plat_type)
      if(screenWidth<=750){
        url = window.location.origin+'/edu_h5/index.html#/'+this.$route.params.plat_type+'/'
        if(hash.indexOf('myCenter')>-1){ //个人中心
          // url = url.replace('myCenter','userYingyou/setting')
          url+='userYingyou/setting'
        }else if(hash.indexOf('LessonTuoyuZT')>-1){//课程列表
          // url = url.replace('LessonTuoyuZT','lessonYingyouZT')
          url+='lessonYingyouZT'
        }else if(hash.indexOf('lessonTuoyu')>-1){//课程列表
          // url = url.replace('LessonTuoyu','lessonYingyou')
          url+='lessonYingyou'
        }else if(hash.indexOf('detail')>-1){//课程详情
          // url = url.replace('detail2','detailYingyou')
          url+='detailYingyou'
        }else if(hash.indexOf('player2')>-1){//视频播放
          // url = url.replace('player2','playerYingyou')
          url+='playerYingyou'
        }else{
          url = window.location.origin +'/edu_h5/index.html'+ hash
        }
        url+=search
        window.location.replace(url)
      }
    },
    setInit() {
      let arr = ["platMap"];
      arr.forEach((item) => {
        this.$store.commit("SET_INIT", { name: item, data: vuexInit[item] });
      });
    },
    async autoLogin() {
      let url = window.location.href;
      if (url.indexOf("?") > -1) {
        let arr = url.split("?")[1].split("&");
        let myIndex = -1
        arr.forEach((item,index) => {
          if (item.split("=")[0] == "mobile") {
            myIndex = index
            // this.login();
            console.log(this.$route.query.name)
            this.$request.mobileLogin({
               mobile:item.split("=")[1],
               name: this.$route.query.name
             }).then(res=>{
              console.log(res)
            let user = {
              ...res.original.base,
              access_token: res.original.access_token,
            };
              localStorage.setItem("user", JSON.stringify(user));
              this.$store.commit("SET_USER", user);
              this.$store.commit("changeLoginShow", false);
              this.$store.commit("SET_LOGINSTATUS", true);
              // this.$store.dispatch("getStudy");
              // this.$store.dispatch("getVIPInfo");
              arr.splice(myIndex,1)
              // window.location.replace(window.location.hash.split('?')[0]+'?'+arr.join('&'))
              this.$router.replace(window.location.hash.replace('#/','/').split('?')[0]+'?'+arr.join('&'))
             })
             
          }
        });
      }
    },
    login() {
      this.$api({
        url: "/api/auth/login",
        method: "GET",
        data: {
          mobile: "18156081988",
          code: "04837",
          channel: 8,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            let { id, mobile, nickname, userface, access_token } = res.data;
            let user = {
              id,
              mobile,
              nickname,
              userface,
              access_token,
            };
            localStorage.setItem("user", JSON.stringify(user));
            this.$store.commit("SET_USER", user);
            this.$store.commit("changeLoginShow", false);
            this.$store.commit("SET_LOGINSTATUS", true);
            this.$store.dispatch("getStudy");
            this.$store.dispatch("getVIPInfo");
          } else {
            this.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading.close();
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
$themeColor: #2e66d0;
.whiteBg{
  background-color: #fff
}
@font-face {
  font-family: "SourceHanSans";
  src: url("./assets/font/sy/SourceHanSansCN-Normal.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansBold";
  src: url("./assets/font/sy/SourceHanSansCN-Bold.otf");
  font-weight: normal;
  font-style: normal;
}
.tip {
  display: none;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis_two {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.ellipsis_three {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}
.van-image__loading-icon,
.van-image__error {
  width: 100%;
  height: 100%;
  .van-icon__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.emptySmall .van-empty__image {
  width: 100px;
  height: 100px;
}
.emtpyMain {
  margin: calc((100vh - 230px) * 0.5 - 160px) auto 0;
}
body,
p,
div {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fafafa;
  color: #333333;
}

a {
  text-decoration: none;
  color: #666;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

#app {
  height: 100vh;
  min-width: 1200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .appContent {
    flex: 1;
    overflow-y: auto;
  }
}

.one-row-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg1 {
  background-color: #a40001;
}

.c1 {
  color: #a40001;
}

.c2 {
  color: #333;
}

.c3 {
  color: #666;
}
.idea-nav,
.idea-list,
.cert-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.idea-nav {
  justify-content: flex-start;
}

.idea-nav-item {
  padding: 0 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-of-type(7) {
    // margin-right: 0;
  }

  &:hover {
    color: #fff;
    background: #a40001;
  }
}

.active {
  color: #fff;
  background: #a40001;
}

.module-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  padding-top: 80px;
  padding-bottom: 35px;
  display: flex;
  justify-content: space-between;

  span {
    color: #999;
    font-size: 18px;
    cursor: pointer;
    font-weight: normal;

    // &:hover {
    //   color: #a40001;

    //   .van-icon {
    //     color: #a40001 !important;
    //   }
    // }
  }
}

.header {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  z-index: 2000;
  background: #fff;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 1);
  filter: drop-shadow(0 1px 10px rgba(0, 0, 0, 0.06));

  &::before {
    content: "";
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 1);
    height: 100%;
    top: 0;
    left: -2000px;
    width: 2000px;
    position: absolute;
  }

  &::after {
    content: "";
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(255, 255, 255, 1);
    height: 100%;
    top: 0;
    right: -2000px;
    width: 2000px;
    position: absolute;
  }
}

.main {
  width: 1200px;
  margin: 0 auto;
  padding-top: 64px;
  overflow: hidden;
  min-height: calc(100vh - 300px);
  box-sizing: border-box;
}

.tuoyu {
  padding-top: 20px;
  overflow: visible;
}
.vipMain {
  width: 100vw;
  min-width: 1200px;
  padding: 0;
}
.searchMain {
  padding: 0;
  width: 100%;
  min-width: 1200rpx;
}
.course-card {
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px #0000000f;
}

.card-hover:hover {
  cursor: pointer;
  transform: translate(0, -6px);
  box-shadow: 0px 14px 36px #0000000f !important;
  transition: all 0.3s ease;
}

.course-card {
  transition: all 0.3s ease;
  width: 100%;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  width: 285px;
  cursor: pointer;
  box-shadow: 0px 0px 10px #0000000f;
  margin-right: 20px;

  &:nth-of-type(4n) {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    transform: translate(0, -6px);
    box-shadow: 0px 14px 36px #0000000f;
    transition: all 0.3s ease;
  }

  .course-card-cover {
    border-radius: 12px 12px 0 0;
    width: 100%;
    position: relative;
    height: 100%;
    height: 0;
    padding-bottom: 56.2%;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    .tip {
      position: absolute;
      top: 0;
      left: 0px;
      z-index: 1;
      img {
        width: 68px;
        // height: 35px;
      }
    }
  }

  .course-card-cover .course-cover {
    padding-bottom: 56.2%;
  }

  .course-card-cover-tag {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 3px;
    line-height: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 12px;
    padding: 0 6px;
    height: 18px;
  }

  .course-card-detail {
    padding: 16px 16px 20px;

    .course-card-title {
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 16px;
    }

    .course-card-subtitle {
      line-height: 18px;
      margin-bottom: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 12px;
    }

    .course-card-btn-container {
      margin-top: 20px;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;

      .course-card-learners {
        font-size: 11px;
        line-height: 18px;
      }

      .course-card-btn {
        cursor: pointer;
        border-radius: 6px;
        line-height: 30px;
        font-weight: 500;
        border-radius: 6px;
        color: #a40001;
        background: #f7f7f7;
        padding: 0 20px;
        font-size: 14px;

        &:hover {
          color: #fff;
          background: #a40001;
        }
      }
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(72, 68, 67, 1);
  color: #fff;
  font-size: 14px;

  overflow: hidden;
  height: 120px;
  p {
    margin: 20px auto;
    text-align: center;
  }
}

.van-overlay,
.van-popup {
  // z-index: 9999 !important;
  // overflow: hidden;
}
.el-cascader__dropdown{
  z-index: 8888 !important;
}
.van-toast {
  z-index: 10000 !important;
}

.van-popup {
  border-radius: 16px;
}

.login-main {
  border-radius: 16px;
  overflow: hidden;
  padding: 30px 40px;
  height: 100%;

  .login-title {
    width: 100%;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    text-align: center;
  }

  .phone-number,
  .auth-code,
  .van-field {
    margin-top: 30px;
    height: 48px;
    border-radius: 8px;
    background: #f7f7f7;
    font-size: 16px;
    color: #333;
    padding-left: 10px;
    width: 270px;
  }

  .no_touch {
    pointer-events: none;
    color: #999;
  }

  .code-btn {
    color: #a40001;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-btn {
    width: 270px;
    margin-top: 40px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
  }

  .agreement {
    padding-top: 16px;
    text-align: center;
    color: #999;
    font-size: 12px;

    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
    }

    span {
      cursor: pointer;
    }
  }
}
</style>
